.start-time {
  position: absolute;
  left: 20px;
  top: 0;
  height: 100%;
  font-size: 14.6vh;
  font-weight: bolder;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 768px) {
    font-size: 13vw;
    line-height: 18vw;
  }

  .start-text {
    display: block;
    font-family: 'Montserrat', sans-serif;
    -webkit-text-stroke: 1px var(--color-black);
    line-height: 13.8vh;
    position: relative;

    @media screen and (max-width: 768px) {
      font-size: 13vw;
      line-height: 18vw;
    }
  }

  .hidden-text {
    opacity: 0;
  }

  .auto-text {
    position: absolute;
    top: 0;
    left: 0;
  }

  .mint-text {
    color: var(--color-black);
    -webkit-text-stroke: 1px var(--color-white);
    transition: color 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
      color: var(--color-gold);
    }
  }

  .no-mint-text {
    color: var(--color-black);
    -webkit-text-stroke: 1px var(--color-white);
    cursor: not-allowed;
    position: relative;

    &::after {
      position: absolute;
      top: 47%;
      left: 0;
      content: '';
      width: 100%;
      height: 6%;
      border: 1px solid var(--color-white);
      background-color: var(--color-black);
    }
  }

  .minting {
    color: var(--color-gold);
    -webkit-text-stroke: 1px var(--color-white);
    cursor: not-allowed;
  }
}

.count-down-container {
  position: absolute;
  top: 0;
  right: calc(7vh + 20px);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 768px) {
    right: calc(10vw + 20px);
  }
}
