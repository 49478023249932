@import 'animate.css/animate.min.css';

:root {
  --color-white: #fff;
  --color-black: #000;
  --color-gray1: #101010;
  --color-gray2: #e6e6e6;
  --color-gray3: #979797;
  --color-discord: #5865f2;
  --color-twitter: #1d9bf0;
  --color-opensea: #2181e2;
  --color-gold: #ffcc4c;
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
  color: var(--color-white);
  user-select: none;
}

body {
  min-height: 100vh;
  background-color: var(--color-black);
  /* background-image: linear-gradient(var(--color-gray1) 1px, transparent 0),
    linear-gradient(90deg, var(--color-gray1) 1px, transparent 0);
  background-size: 60px 120px; */
}

::-webkit-scrollbar {
  width: 6px;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--color-white);
}

body::-webkit-scrollbar-track {
  background: transparent;
}
