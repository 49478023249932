.coming-soon {
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);
  height: 100%;
  font-size: 9.8vw;
  font-weight: bolder;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
  -webkit-text-stroke: 1px var(--color-black);
}
