.count-down {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #ffffff;
  transform: translateX(50%) translateY(-50%) rotate(90deg);
  transform-origin: bottom;

  .time-text,
  .slash {
    font-family: 'Montserrat', sans-serif;
    font-size: 6.4vh;
    font-weight: bold;
    -webkit-text-stroke: 1px var(--color-black);

    @media screen and (max-width: 768px) {
      font-size: 10vw;
    }
  }

  .time-text {
    width: 12vh;
    text-align: center;
  }
}
