.auto-type {
  // font-family: 'Montserrat', sans-serif;
  font-weight: bolder;

  &:after {
    content: '|';
    animation: blink 1000ms infinite;
    font-weight: normal;
    // color: var(--color-black);
    // -webkit-text-stroke: 1px var(--color-white);
  }
}

@keyframes blink {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
