@keyframes rotating {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  animation: rotating 0.6s linear infinite;
}
