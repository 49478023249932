.member-card-container {
  width: 100%;
  position: relative;
  margin-top: 20px;

  .card-container {
    position: absolute;
  }

  img {
    width: 100%;

    &.hidden {
      position: relative;
      visibility: hidden;
    }
  }

  .card-name {
    position: relative;
    margin-top: 16px;
    font-size: 14px;
    padding-bottom: 4px;
    border-bottom: 1px solid #e6e6e6;
  }
}
