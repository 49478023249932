.wallet-btn {
  transition: all 0.2s ease-in-out;
  font-size: 12px;
  padding: 0 16px;
  line-height: 44px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin: 0 10px;
  border: 2px solid var(--color-black);
  background-color: var(--color-black);
  color: var(--color-white);

  svg {
    display: inline-block;
    margin-left: 6px;
    width: 20px;
    height: 20px;

    path {
      fill: var(--color-white);
      transition: all 0.2s ease-in-out;
    }
  }

  &:hover,
  &:focus {
    color: var(--color-black);
    background-color: var(--color-white);
    border: 2px solid var(--color-black);

    svg path {
      fill: var(--color-black);
    }
  }
}