$mainPaddingTop: 42px;
$mainPaddingBottom: 120px;

.scc {
  .scc-header {
    padding: 12px 16px;
    background-color: var(--color-white);
  }

  .scc-main {
    position: relative;
    top: -1px;
    height: calc(100vh - 96px - $mainPaddingTop - $mainPaddingBottom);
    overflow: hidden;
    padding: $mainPaddingTop 0 $mainPaddingBottom;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 16%;
      background-color: var(--color-white);
    }
  }

  .scc-footer {
  }
}
