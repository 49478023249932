.start-info-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  // background: url('../../assets/imgs/scc-bg.png') no-repeat;
  // background-size: contain;
  // background-position: center;

  &.hide {
    visibility: hidden;
  }

  .img-container {
    position: relative;

    img {
      max-width: 100%;
      height: 100%;
      filter: blur(3px);
    }
  }
}
