.footer-container {
  margin: 40px 0;

  .footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .line {
    margin: 20px 0;
    width: 100%;
    height: 1px;
    opacity: 0.33;
    background: var(--color-gray2);

    &.line1 {
      width: 10px;
    }

    &.line2 {
      flex: 1;
    }
  }

  svg {
    margin: 0 8px;
    width: 80px;
    fill: var(--color-white);
  }

  .footer-menu {
    list-style: none;
    display: flex;
    margin: -20px 108px;
    position: relative;

    @media screen and (max-width: 768px) {
      margin: 0 40px;
    }

    .menu-item {
      padding: 16px 12px;
      font-size: 18px;
      color: var(--color-white);
      font-weight: bold;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      position: relative;

      @media screen and (max-width: 768px) {
        position: initial;

        &::after {
          display: none;
        }
      }

      .popover-container {
        position: absolute;
        width: 274px;
        left: 0;
        bottom: 54px;
        box-sizing: border-box;
        background-color: var(--color-black);
        border: 1px solid var(--color-white);
        padding: 8px 12px;
        border-bottom-width: 2px;
        opacity: 0;
        z-index: -1;
        transition: opacity 0.28s ease-in-out;

        &.popover-container-2 {
          width: 480px;
        }

        @media screen and (max-width: 768px) {
          width: 60vw;
          left: 12px;

          &.popover-container-2 {
            width: 60vw;
            left: auto;
            right: 0;
          }
        }

        .popover-content {
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          color: var(--color-white);
        }
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 12px;
        left: 12px;
        height: 3px;
        width: 0;
        background-color: var(--color-white);
        transition: all 0.2s ease-in-out;
      }

      &:hover {
        .popover-container {
          opacity: 1;
          z-index: 1;
        }
    
        &::after {
          width: calc(100% - 24px);
        }
      }
    }
  }
}
