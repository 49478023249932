.header-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .header-left {
    display: flex;
    align-items: center;

    .logo-container {
      svg {
        width: 68px;
        height: 68px;
        fill: var(--color-black);
        background-color: var(--color-white);
        cursor: pointer;
      }
    }

    .menu-container {
      display: flex;
      align-items: center;
      list-style: none;
      padding: 0 24px;

      .menu-item a {
        padding: 16px 12px;
        font-size: 18px;
        color: var(--color-black);
        font-weight: bold;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        position: relative;
        text-decoration: none;

        &.active {
          color: var(--color-gray1);
        }

        &::after {
          content: '';
          position: absolute;
          bottom: 12px;
          left: 12px;
          height: 3px;
          width: 0;
          background-color: var(--color-black);
          transition: all 0.2s ease-in-out;
        }

        &:hover {
          &::after {
            width: calc(100% - 24px);
          }
        }
      }

      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }

  .header-right {
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) {
      display: none;
    }

    .social-container {
      display: flex;
      align-items: center;

      .social {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        background-color: var(--color-black);
        border-radius: 28px;
        margin: 0 12px;
        transition: all 0.2s ease-in-out;

        &.discord:hover {
          background-color: var(--color-discord);
        }

        &.twitter:hover {
          background-color: var(--color-twitter);
        }

        &.opensea:hover {
          background-color: var(--color-opensea);
        }

        &.opensea {
          svg {
            transform: scale(1.5);
          }
        }

        svg {
          width: 18px;
          height: 18px;
          fill: var(--color-white);
          transition: all 0.2s ease-in-out;
        }
      }
    }

    .wallet-container {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      margin-left: 16px;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }

  .header-menu-toggle {
    display: none;
    width: 24px;
    height: 24px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    z-index: 99;

    @media screen and (max-width: 768px) {
      display: inline-block;
    }

    .hamburger {
      background-color: var(--color-black);
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 24px;
      height: 3px;
      transition: all 0.42s cubic-bezier(0.6, 0, 0.2, 1);

      &::before,
      &::after {
        content: '';
        display: block;
        height: 3px;
        background: var(--color-black);
        position: absolute;
        left: 0;
        transition: all 0.42s cubic-bezier(0.6, 0, 0.2, 1);
        width: 70%;
        top: calc(50% + 6px);
      }

      &::before {
        width: 70%;
        top: calc(50% + 6px);
      }

      &::after {
        width: 100%;
        top: calc(50% + 14px);
      }
    }

    &.show {
      .hamburger {
        background: 0 0;
        transform: translateY(6px);

        &::before {
          transform: rotate(135deg) translateY(11px) translateX(6px);
          width: 100%;
        }

        &::after {
          transform: rotate(-135deg) translateY(-1px) translateX(16px);
        }
      }
    }
  }
}

.phone-menu-container {
  display: none;

  @media screen and (max-width: 768px) {
    display: block;

    &.hidden {
      display: none;
    }
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.42s cubic-bezier(0.6, 0, 0.2, 1);
    opacity: 1;

    &.hidden {
      opacity: 0;
    }
  }

  .phone-menu-content {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100vw;
    background-color: var(--color-white);
    height: auto;
    transition: all 0.42s cubic-bezier(0.6, 0, 0.2, 1);

    &.hidden {
      height: 0;
    }
  }

  .menu-container {
    padding: 80px 0 20px;
    list-style: none;

    .menu-item a {
      display: block;
      padding: 16px;
      font-weight: bold;
      text-align: center;
      color: var(--color-black);
      text-decoration: none;
      text-align: center;
    }
  }

  .social-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 48px;

    .social {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      border-radius: 28px;
      margin: 0 12px;
      transition: all 0.2s ease-in-out;

      &.discord {
        background-color: var(--color-discord);
        svg {
          fill: var(--color-white);
        }
      }

      &.twitter {
        background-color: var(--color-twitter);
        svg {
          fill: var(--color-white);
        }
      }

      &.opensea {
        background-color: var(--color-opensea);
        svg {
          transform: scale(1.5);
        }
      }

      svg {
        width: 18px;
        height: 18px;
        fill: var(--color-white);
        transition: all 0.2s ease-in-out;
      }
    }
  }
}
